'use strict'

import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue'
import SweetScroll from 'sweet-scroll';
import axios from 'axios'
import $ from "jquery";

var spMenuView = new Vue({
  el: '.c-header__spMenu',
  data: {
    btn: false,
    dropMenu01: false,
    dropMenu02: false,
    dropMenu03: false
  }
});

/*
 * Google Map呼び出し関数
 */
let map;
let markers = [];

async function InitMap() {
  let pathname = "";
  const menu = document.querySelectorAll('p[data-area]');
  const def = {lat: 35.664791, lng: 139.670159};

  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 5,
    center: def,
  });

  // setting marker
  const addMarker = (data) => {
    Object.keys(data).map(function(k, i) {
      let element = data[k];
      for (let i = 0; i < element.length; i++) {
        let elm = {
          "lat" : element[i].lat,
          "lng" : element[i].lng,
          "link": element[i].link,
          "thumbnail": element[i].thumbnail,
        }
        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(elm.lat, elm.lng),
          map: map,
          icon: {
            url: "/wp-content/themes/sotonoba/resources/assets/images/map/pin@2x.png",
            scaledSize: new google.maps.Size(34,54)
          }
        });
        markerInfoBox(marker,elm);
        markers.push(marker);
      }
    });
  }
  const deleteMarkers = () =>{
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
  }
  const updateMarkers = (data,c) => {
    if(data[c]) {
      Object.keys(data[c]).map(function(k, i) {
        let elm = {
          "lat" : data[c][k].lat,
          "lng" : data[c][k].lng,
          "link": data[c][k].link,
          "thumbnail": data[c][k].thumbnail,
        }
        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(elm.lat, elm.lng),
          map: map,
          icon: {
            url: "/wp-content/themes/sotonoba/resources/assets/images/map/pin@2x.png",
            scaledSize: new google.maps.Size(34,54)
          }
        });
        markerInfoBox(marker,elm);
        markers.push(marker);
      });
    }
  }

  // click event
  const markerSort = (data,m) => {
    for (let i = 0; i < m.length; i++) {
      m[i].addEventListener('click', () => {

        let category = m[i].dataset.area || "";
        if(category) {
          // get position
          const cat_zoom = Number(m[i].dataset.zoom);
          const cat_latLng = {
            lat: Number(m[i].dataset.lat),
            lng: Number(m[i].dataset.lng)
          };

          // update marker
          deleteMarkers();
          updateMarkers(data,category);

          // update position
          map.setZoom(cat_zoom);
          map.setCenter(cat_latLng);
        }
      });
    }
  }

  // create window
  const markerInfoBox = (m,e) => {
    google.maps.event.addListener(m, 'click', () => {
      let thumbnail = "";
      if(e.thumbnail !== undefined) {
        thumbnail = e.thumbnail.sizes['post-thumbnail']
      } else {
        thumbnail = "/wp-content/themes/sotonoba/resources/assets/images/common/thumb_default_img.jpg";
      }
      let infoboxContent = document.createElement('div');
      infoboxContent.innerHTML =
        `<div class="c-map__infoWindow">
          <a href='${e.link[0].post_name}'>
            <div class="c-article__list-image">
              <img src="${thumbnail}">
            </div>
            <div class="c-article__list-title">
              <p>${e.link[0].post_title}</p>
            </div>
          </a>
        </div>`;
      var infoboxOptions = {
        content: infoboxContent, // 生成したDOMを割り当てる
        pixelOffset: new google.maps.Size(-137, -75), // オフセット値
        alignBottom: true, // 位置の基準
        boxClass: "gmap-info-window", // 生成したDOMをラップするdivのclass名
        closeBoxMargin: "-40px -40px 0px 0px", // 閉じるボタンの位置調整
        closeBoxURL: "/wp-content/themes/sotonoba/resources/assets/images/map/close.png" // 閉じるボタンの画像パス
      };
      var infobox = new InfoBox(infoboxOptions);
      infobox.open(map, m);
    });

  }

  // get api
  const ajaxInitMap = async () => {
    const url = window.location.host;
    let items = {};
    let MAP_ENDPOINT = `//${url}/wp-json/acf/v3/googlemap/?per_page=9999`;

    const response = await axios.get( MAP_ENDPOINT )
    .then(res => {

      for (let i = 0; i < res.data.length; i++) {
        let map_area = new Array();
        map_area.push(res.data[i].acf.map_area);

        let map_data = {
          lat: res.data[i].acf.map_google.lat,
          lng: res.data[i].acf.map_google.lng,
          link: res.data[i].acf.map_link,
          thumbnail: res.data[i].acf.map_thumbnail
        };

        if(!items[map_area]) {
          items[map_area] = [];
          items[map_area].push(map_data);
        } else {
          items[map_area].push(map_data);
        }
      }
    });
    return items;
  }

  let api_data = await ajaxInitMap();
  await addMarker(api_data);
  await markerSort(api_data,menu);
}

/**
 * メンバー紹介の絞り込み
 */
class MemberRefine {
  constructor() {
    this.prop = {
      category: document.querySelectorAll('.js-memberRefine__cat'),
      listItem: document.querySelectorAll('.js-memberRefine__list'),
      data: "data-member-refine"
    }
  }
  func() {
    for (let i = 0; i < this.prop.category.length; i++) {
      const element =  this.prop.category[i];
      // this.load(element);
      this.click(element)
    }
  }

  // データ属性を配列で取得
  data_list(e) {
    let d = e.getAttribute(this.prop.data);
    let arr;
    if(!Array.isArray(d)) {
      arr = d.split(',')
    }
    return arr;
  }

  // フィルターのマッチ部分
  filter_match(arr_list,arr_cat) {
    let check = false;
    for (let i = 0; i < arr_list.length; i++) {
      const element = arr_list[i];
      if(element === arr_cat) {
        check = true;
        break;
      }
    }
    return check;
  }

  filter(elm, arr_list, arr_cat) {
    let check = false;
    check = this.filter_match(arr_list,arr_cat)
    if(check === true) {
      return elm;
    }
  }
  click(e) {
    const input = e.querySelector('input');
    const input_value = input.value;
    // radioボタンが変更されたら、data-*がマッチするlistItemを調べる
    e.addEventListener('change', () => {
      for (let i = 0; i < this.prop.listItem.length; i++) {
        const list = this.prop.listItem[i];
        // list.classList.remove('is-active')
        list.style.display = 'none';

        // listItemのdata-*一覧を取得
        const list_data = this.data_list(list)

        // マッチする要素の検索
        const fil = this.filter(list, list_data, input_value)

        // マッチする要素に処理
        if(fil) {
          fil.classList.add('is-active')
          $(fil).fadeIn(100);
          // console.log(fil, 'は表示します')
        }
      }
    });
  }
}
const memberRefine = new MemberRefine();
memberRefine.func();

/**
 * トップページの新着記事を追加で呼び出す
 */
class InfScroll {
  constructor(selector) {
    this.url = window.location.host;
    this.count = 0;
    this.wrap = document.querySelector(selector) || '';
    this.elem = document.querySelector(selector + " .inf-elem");
    this.btn = document.querySelector(selector + " .inf-btn");
    this.DEFAULT_ENDPOINT = `//${this.url}/wp-json/wp/v2/posts/?_embed`;
  }

  init(offset) {
    if( this.wrap ) {
      const _btn = this.btn;
      let _count = this.count;
      let _isLoading = false;

      _btn.addEventListener('click', async () => {
        if(!_isLoading) {
          await _btn.classList.add('is-loading');
          _isLoading = await true;

          const json = await this.getArticleAPI(_count,offset);
          await this.createDom(json);
          await _btn.classList.remove('is-loading')
          await _count ++;

          _isLoading = await false;
        }
      });
    }
  }

  getThumbnail(embedded) {
    let _thumbnail = "";
    const _no_image_src = "/wp-content/themes/sotonoba/resources/assets/images/common/thumb_default_img.jpg";
    const _in_featuredmedia = 'wp:featuredmedia' in embedded;

    let _in_media_details,
        _in_source_url,
        _in_media_sizes,
        _in_post_thumbnail;

    if(_in_featuredmedia) {
      const _media_prop = embedded['wp:featuredmedia'][0]
      _in_media_details = 'media_details' in _media_prop
      _in_source_url = 'source_url' in _media_prop

      if(_in_media_details) {
        _in_media_sizes = 'sizes' in _media_prop['media_details']

        if(_in_media_sizes) {
          _in_post_thumbnail = 'post-thumbnail' in _media_prop['media_details']['sizes']
        }
      }
    }

    /**
     *
     */
    if(_in_post_thumbnail) {
      return embedded['wp:featuredmedia'][0]['media_details']['sizes']['post-thumbnail']['source_url'];
    } else if(_in_source_url) {
      return embedded['wp:featuredmedia'][0]['source_url'];
    } else {
      return _no_image_src;
    }
  }

  getTermName(embedded) {
    const _term_data = embedded['wp:term'][0][0].slug;
    let _term_text = _term_data;
        _term_text = _term_text.charAt(0).toUpperCase() + _term_text.slice(1);
        _term_text = _term_text.substring(0, 1).toUpperCase() + _term_text.substring(1);
        _term_text = _term_text.replace(/^[a-z]/g, function(val) {
          return val.toUpperCase();
        });
    return _term_text
  }

  getUlike(data) {
    if(!data['wp_ulike_snippet']) return "";

    const _ulikeElement = data['wp_ulike_snippet'];
    return '<div class="c-article__list-like">' + _ulikeElement + '</div>';
  }

  createDom(res) {
    let _ul = this.elem;

    if( res ) {
      const _keys = Object.keys(res);

      for (const k in _keys) {
        if (!_keys.hasOwnProperty(k)) return;
        const _res = res[k];
        const _embedded = _res['_embedded'];

        const _thumbnail = this.getThumbnail(_embedded);
        const _term_name = this.getTermName(_embedded);

        const _ulike = this.getUlike(_res);
        console.log(_ulike);

        /**
         * その他情報の取得
         */
        const _date_data = _res.date.split("T"); // 日付情報取得
        const _date = _date_data[0];
        const _permalink = _res.link; // パーマリンク取得
        const _title = _res.title.rendered; // 見出し取得

        /**
         * 出力するDOM情報の作成
         */
        let _dom = document.createElement('li');
            _dom.innerHTML =
          `<a href="${_permalink}">
            <article class="c-article__list">
              <div class="c-article__list-image"><img src="${_thumbnail}"></div>
              <div class="c-article__list-util">
                <p class="c-article__list-category">${_term_name}</p>
                <time class="c-article__list-data" datetime="x">${_date}</time>
              </div>
              <h3 class="c-article__list-title">${_title}</h3>
            </article>
          </a>
          ${_ulike}`;
        _ul.appendChild(_dom);
      }
    }
  }

  async getArticleAPI(c,o) {
    let _json;
    const _per_page = 8;
    const _click_count = c * _per_page;
    const _offset = o + _click_count;

    let _param  = "&per_page=" + _per_page;
        _param += "&offset=" + _offset;

    const _ENDPOINT = this.DEFAULT_ENDPOINT + _param;

    const _response = await axios.get( _ENDPOINT )
      .then(res => {
        _json = res.data;
      })
    ;

    return _json;
  }
}

const infScroll_home = new InfScroll('#inf-wrap');
infScroll_home.init(8);

/**
 *
 */
document.addEventListener('DOMContentLoaded', () => {
  var mapPage = document.getElementById('map') || '';
  if(mapPage) {
    var initMap = new InitMap();
  }
  const scroll = new SweetScroll();
}, false);